<template>
    <div>
        <div v-if="this.am_allowed('product.can_see_product_materials')">
            <div class="columns">
                <div class="column">
                    <div class="level p-0 m-0">
                        <div class="level-left">
                            <div class="level-item">Lista e materialeve</div>
                            <input class="input" style="margin: 5px; width: 300px" type="text" placeholder="Kerko..." v-model="global_search_filter" @input="onFilterTextBoxChanged">
                        </div>
                        <div class="level-right">
                            <div class="level">
                            <a v-if="am_allowed('product.can_create_product_materials')"  @click.prevent="create_material">
                                <i class="fa fa-plus"></i>
                            </a>
                            </div>
                        </div>
                    </div>
                    <ag-grid-vue
                        style="width: 100%; height: 200px"
                        class="ag-theme-balham"
                        :columnDefs="columnDefs"
                        :rowData="rowData.value"
                        :rowSelection="rowSelection"
                        :context="context"
                        :localeText="localeText"
                        :animateRows="true"
                        @grid-ready="onGridReady"
                    >
                    </ag-grid-vue>
                </div>
                <div class="column is-5" v-if="form_edit || form_create">
                    <DaForm v-if="form_create" @submitted="submitMaterial" :form="form_create">
                    </DaForm>

                    <DaForm v-if="form_edit" 
                    @submitted="submitMaterial" 
                    :form="form_edit" 
                    >
                    </DaForm>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import DaForm from "@/components/daform/index.vue";
import ButtonsCell from "@/components/buttonscell/ButtonsCell.vue";
import createMaterial from "./forms/create";
import editMaterial from "./forms/edit";
import MaterialsServices from "@/services/Material";
import locale from "@/components/ag-grid/locale/al/al.js"
import socket from '@/socket.js';
import { onUnmounted, reactive, inject } from 'vue';
import permissionMixin from '@/mixins/permissions-mixin.js'
export default {
mixins: [permissionMixin],

components: {
    AgGridVue,
    DaForm
},
setup() {
    let rowData = reactive([])
    const toast = inject('toast')
    socket.connect()
    socket.on('welcome', data => {
        console.log('welcome', data)
    })
    socket.on("propagate", data => {
        if(data.action == "create"){
            var a = rowData.value
            a.push(data.data)
            rowData.value = [...a]
            toast.success('Materiali u krijua nga ' + data.created_by.name)
        }
        if(data.action == "edit"){
            rowData.value = [...rowData.value.map(e => {
                if(e.id == data.data.id){
                    return data.data
                }
                return e
            })]
            toast.success('Materiali u modifikua nga ' + data.created_by.name)
        }
    })
    onUnmounted(() => {
        socket.off('welcome')
        socket.off('propagate')
        socket.disconnect()
    })
    return { 
        rowData
    }
},
beforeMount() {
    this.context = { componentParent: this }
},
data() {
    return {
        global_search_filter: "",
        context: null,
        selectedMaterial: {},
        form_create: null,
        form_edit: null,
        columnDefs: [
            { headerName: "Kodi", field: "code", filter: true, floatingFilter: true, sortable: true },
            { headerName: "Emri", field: "name", filter: true, floatingFilter: true, sortable: true },
            { headerName: "Njesia", field: "unit_name", filter: true, floatingFilter: true, sortable: true },
            { headerName: "TVSH(%)", field: "vat", filter: true, floatingFilter: true, sortable: true },
            { headerName: "Sasia", field: "amount", filter: true, floatingFilter: true, sortable: true },
            {
                headerName         : "Action",
                cellRenderer       : ButtonsCell,
                cellRendererParams : this.context,
            }
        ],
        gridApi: null,
        defaultColDef: {
            flex: 1,
            minWidth: 100
        },
        rowSelection: "single",
        localeText: null
    }
},
methods: {
    onFilterTextBoxChanged() {
        this.gridApi.setQuickFilter(this.global_search_filter)
    },
    async open(mode, material) {
        if(mode == "1") {
            if(this.am_allowed('product.can_edit_product_materials')){
                this.form_create = null
                if(material) {
                    this.form_edit = null
                    this.form_edit = { loading: true }
                    this.form_edit = await editMaterial(material)
                    this.selectedMaterial = material
                    this.gridApi.sizeColumnsToFit();
                }
            } else {
                this.$toast.error('Ju nuk keni te drejta te modifikone materiale.')
            }
        }
        if(mode == '0'){
            if(this.am_allowed('product.can_see_material_details')){
                // console.log('show mat details')
            } else {
                this.$toast.error('Ju nuk keni te drejta te shihni detajet e materialeve.')
            }
        }
        
    },
    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
    },
    async create_material() {
        this.form_edit = null
        this.form_create = { loading: true }
        this.form_create = await createMaterial()
        this.gridApi.sizeColumnsToFit();
    },
    async submitMaterial(m) {
        var notify_text = ""
        if(this.form_create != null) {
            let material = await MaterialsServices.createMaterial(m)
            var a = this.rowData.value
            a.push(material)
            this.rowData.value = [...a]
            socket.emit("propagate", {action: "create", data: material})
            notify_text = "Materiali u krijua me sukses"
            this.form_create = null
            this.$root.selected_one = {}
        }
        else {
            m.guid = this.selectedMaterial.guid
            let material = await MaterialsServices.editMaterial(m)
            this.rowData.value = [...this.rowData.value.map(a => {
                if(a.guid == material.guid) a = {...material}
                return a
            })]
            socket.emit("propagate", {action: "edit", data: material})
            notify_text = "Materiali u modfikua me sukses"
            this.form_edit = null
            this.$root.selected_one = {}
        }
        this.$toast.success(notify_text)
    }
},

async created() {
    if(!this.am_allowed('product.can_see_product_materials')){
        window.location.href = '/home'
    }
    this.localeText = locale.locale_al()
    this.rowData.value = await MaterialsServices.getMaterials()
},

onBeforeDestroy() {
      socket.disconnect();
  },

}
</script>

<style lang="scss" scoped>

</style>