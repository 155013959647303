import Api from "@/services/Api";

export default {
    async getMaterials() {
        var materials = []
        await Api(true).post("material/list", {})
        .then(r => {
            materials = r.data
        })
        return materials
    },

    async createMaterial(material) {
        await Api(true).post("material/create", material)
        .then(r => {
            material = r.data
        })
        return material
    },

    async editMaterial(material) {
        await Api(true).post("material/edit", material)
        .then(r => {
            material = r.data
        })
        return material
    },

    async getUnits() {
        var units = []
        await Api(true).post("unit/list", {})
        .then(r => {
            units = r.data.map(e => {
                e.label = e.name
                return e
            })
        })
        return units
    },

    async getVat() {
        var vat = []
        await Api(true).post('vat/list', {})
        .then(r => {
            vat = r.data.map(e => {
                e.label = e.value
                return e
            })
        })
        return vat
    },
}