import MaterialsServices from "@/services/Material";

export default async function (ma){

    var unit = await MaterialsServices.getUnits()
    var vat = await MaterialsServices.getVat()

        return {
            name: "Modifiko materialin",
            submit: {
                label: "Modifiko materialin",
                action: 'submitMaterial'
            },
            fields: [

                [{
                    value: ma.code,
                    name: 'code',
                    label: 'Kodi',
                    type: 'text-field',
                    style: "min-width: 45%",
                    required: true,
                    required_text: 'Kjo fushe eshte e detyrueshme',
                    unique: {
                        model: 'material',
                        field: 'code',
                        where: [
                            {
                            column: 'guid',
                            condition: '!=',
                            value: ma.guid
                        }
                        ]
                    },
                    left_icon: 'fa-solid fa-hashtag'
                },
                {
                    value: ma.name,
                    name: 'name',
                    label: 'Emri',
                    type: 'text-field',
                    style: 'min-width: 45%',
                    required: true,
                    required_text: 'Kjo fushe eshte e detyrueshme'
                }],
                [
                    {
                        value: ma.type_id,
                        name: "type_id",
                        label: "Njesia",
                        type: "select-field",
                        options: unit,
                        style: "min-width: 30%",
                        required: true,
                        required_text: "Kjo fushe eshte e detyrueshme",
                    },
                    {
                        value: ma.amount,
                        name: "amount",
                        label: "Sasia",
                        type: "number-field",
                        style: "min-width: 30%",
                        required: true
                    },
                    {
                        value: ma.vat,
                        name: "vat",
                        label: "TVSH(%)",
                        type: "select-field",
                        options: vat,
                        style: "min-width: 30%",
                        required: true,
                    },
                ]
            ]
        }
}
