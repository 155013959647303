import MaterialsServices from "@/services/Material";

export default async function() {

  var unit = await MaterialsServices.getUnits()
  var vat = await MaterialsServices.getVat()

      return {
        name: "Krijo Material",
        submit: {
          label: "Krijo",
          action: "submitMaterial",
        },
        fields: [
          [
            {
              value: "",
              name: "code",
              label: "Kodi",
              type: "text-field",
              style: "min-width: 45%",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
              unique: {
                model: "material",
                field: "code",
                where: [],
              },
              left_icon: "fa-solid fa-hashtag",
            },
            {
              value: "",
              name: "name",
              label: "Emri",
              type: "text-field",
              style: "min-width: 45%",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
            },
          ],

          [
            {
                name: "type_id",
                label: "Njesia",
                type: "select-field",
                options: unit,
                style: "min-width: 30%",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
            {
              value: "",
              name: "amount",
              label: "Sasia",
              type: "number-field",
              style: "min-width: 30%",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
            },
            {
              name: "vat",
              label: "TVSH(%)",
              type: "select-field",
              options: vat,
              style: "min-width: 30%",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
          },
          ],
        ],
      };
  }
  
  